import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { RequestFormService } from '../services/request-form.service';
import { ToastrService } from 'ngx-toastr';
import { RequestFormSuccessComponent } from '../popup/request-form-success/request-form-success.component';
import { NgxSpinnerService } from "ngx-spinner";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.css']
})
export class RequestFormComponent implements OnInit {
  Form: FormGroup;
  // checkDelivery = true;
  // checkRemoval = false;
  checkSwap = false;
  submitted = false;
  emailArray: any = [];
  swapDateArray: any = [];
  // file
  fileUploadData: any = [];
  fileError: string = '';
  fileSuccess: string = '';
  uploadedFile: any = '';
  myFile: string= '';
  constructor(private formBuilder: FormBuilder, private modalService: NgbModal,
              private toastr: ToastrService, private requestFormService: RequestFormService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.Form = this.formBuilder.group({
      customer_name: ['', Validators.required],
      // file_name: [''],
      po_number: ['', Validators.required],
      location: ['', Validators.required],
      drop_off_date: [null, Validators.required],
      pick_up_date: [null],
      container_type: ['', Validators.required],
      last_name: [''],
      first_name: ['', Validators.required],
      special_instructions: [''],
      service_type: [false],
      email: this.formBuilder.array([this.formBuilder.control(null, Validators.required)]),
      swapDate: this.formBuilder.array([this.formBuilder.control(null, Validators.required)]),
      phone_number: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9+d]{10}$')])],
  });
}
get f() {return this.Form.controls; }

    getEmail(): AbstractControl[] {
       return (this.Form.get('email') as FormArray).controls;
    }

    addEmail() {
      for (let index = 0; index < 1; index++) {
          (this.Form.get('email') as FormArray).push(this.formBuilder.control(null, Validators.required))
        }
      }

    serviceChange(evt: any) {
     
      if (evt.target.checked) {
        this.checkSwap= true;
      } else {
        this.checkSwap= false;
      }
  }

     getSwapDate(): AbstractControl[] {
      return (this.Form.get('swapDate') as FormArray).controls;
   }

   addSwapDate() {
     for (let index = 0; index < 1; index++) {
         (this.Form.get('swapDate') as FormArray).push(this.formBuilder.control(null, Validators.required))
       }
     }

     deleteSwapDate(index: number, type: any) {
        (this.Form.get('swapDate') as FormArray).removeAt(index);
    }

    deleteRow(index: number, type: any) {
      if (type === 'swap') {
        (this.Form.get('swapDate') as FormArray).removeAt(index);
      } else if (type === 'email') {
        (this.Form.get('email') as FormArray).removeAt(index);
      }
   }

  // start  file upload

  onFileUpload() {
    this.fileUploadData = [];
    const fileUpload = document.getElementById('docUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip','txt'];
      var re = /(?:\.([^.]+))?$/;
      var files = fileUpload.files;
      var file;
      // loop through files
      for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if (file && filecheck.indexOf(ext) != -1) {
          let size = file.size / 1024 / 1024;
          if (size > 50) {
            this.fileError = 'File size should not be more than 50MB.';
            return;
          }
          this.fileUploadData.push(file);
        }
        else {
          this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
        }
      }
      this.addDocument(this.fileUploadData);
    };

    fileUpload.click();
  }

  // addDocument
  addDocument(file) {
    this.fileSuccess = '';
    var uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append('file_name', file[x]);
     }
    this.requestFormService.uploadFile(uploadData)
      .subscribe((response) => {
        this.uploadedFile = response.file_name;
        if(file.length>0)
        {
          this.myFile = file[0].name;
        }else
        {
          this.myFile = '';
          this.fileSuccess = '';
        }
        this.fileSuccess = 'Uploaded successfully!!';
      });
  }

  popup()
  {
    const modalRef = this.modalService .open(RequestFormSuccessComponent, { size: 'md', 
    centered: true, windowClass: 'modal-custom'}).result.then((result) => {
      window.location.href = '';
    }, (reason) => {
      window.location.href = '';
    });
  }

  selectSupply(evt: any)
   {
    if (evt.target.checked) {
        this.Form.patchValue({
          po_number: 'Supply Later',
        });
    } else {
        this.Form.patchValue({
          po_number: '',
        });
    }
}

deleteFile()
{
  this.uploadedFile = '';
  this.myFile = '';
  this.fileError = '';
  this.fileSuccess = '';
  this.fileUploadData = [];
}
onSubmit()
{
  if(this.Form.value.customer_name === '')
  {
    this.toastr.error('Customer name is required.');
    return;
  }

  if(this.Form.value.first_name === '')
  {
    this.toastr.error('First name is required.');
    return;
  }

  const  regPhone = /^[0-9+d]{10}$/;
  if(this.Form.value.phone_number === '')
  {
    this.toastr.error('Phone number is required.');
    return;
  }else if (!regPhone.test(this.Form.value.phone_number))
  {
    this.toastr.error(' Please enter 10 digits phone number.');
    return;
  }

  if(this.Form.value.email === '')
  {
    this.toastr.error('Email is required.');
    return;
  }
  this.emailArray = [];
  if(this.f.email.value[0] != null)
  {
      // tslint:disable-next-line: prefer-for-of
      const  reg = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/; 
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < this.f.email.value.length; index++)
      {
        if(this.f.email.value[index] != null && (reg.test(this.f.email.value[index])) )
        {
          this.emailArray.push(this.f.email.value[index]);
        }else{
          this.toastr.error('Please enter correct email.');
          return;
        }
      }
  }else{
    this.toastr.error('Email is required.');
    return;
  }

  if(this.Form.value.location === '')
  {
    this.toastr.error('Service location is required.');
    return;
  }

  if(this.Form.value.container_type === '')
  {
    this.toastr.error('Container type is required.');
    return;
  }

  if(this.Form.value.po_number === '')
  {
    this.toastr.error('Po number is required.');
    return;
  }

  if(this.Form.value.drop_off_date === null || this.Form.value.drop_off_date === '')
  {
    this.toastr.error('Date for bin drop off is required.');
    return;
  }

  if(this.Form.value.drop_off_date === '' || this.Form.value.drop_off_date === '')
  {
    this.Form.value.drop_off_date = null;
  }
  this.swapDateArray = [];
  if(this.Form.value.service_type === true)
  {
    if(this.f.swapDate.value[0] != null )
    {
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < this.f.swapDate.value.length; index++)
        {
          if(this.f.swapDate.value[index] != null )
          {
            this.swapDateArray.push(this.f.swapDate.value[index]);
          }else if(this.f.swapDate.value[index] === null){
            this.toastr.error('Swap date is required.');
            return;
          }
        }
    }else{
      this.toastr.error('Swap date is required.');
      return;
    }
  }else
  {
    this.swapDateArray = [];
  }

  const sendObj = {
      customer_name: this.Form.value.customer_name,
      first_name: this.Form.value.first_name,
      last_name:  this.Form.value.last_name,
      phone_number: this.Form.value.phone_number,
      email: this.emailArray,
      location: this.Form.value.location,
      container_type: this.Form.value.container_type,
      po_number: this.Form.value.po_number,
      service_type: this.Form.value.service_type,
      drop_off_date: this.Form.value.drop_off_date,
      pick_up_date: this.Form.value.pick_up_date,
      swapDate: this.swapDateArray,
      special_instructions: this.Form.value.special_instructions,
      file_name: this.uploadedFile
  }

  this.requestFormService.add(sendObj)
      .subscribe((response) => {
       this.toastr.success('Work order submitted');
       this.popup();
      });
}

}

