import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RequestFormComponent } from './main/request-form/request-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Datepicker module
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RequestFormService } from './main/services/request-form.service';
import { ToastrModule } from 'ngx-toastr';
import { RequestFormSuccessComponent } from './main/popup/request-form-success/request-form-success.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ErrorInterceptor } from './main/_helpers/error.interceptor';
import { NgxMaskModule, IConfig} from 'ngx-mask';
export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  declarations: [
    AppComponent,
    RequestFormComponent,
    RequestFormSuccessComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule, ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    NgbModule,
    NgxSpinnerModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      maxOpened: 1,
      autoDismiss: true,
      enableHtml: true,
      preventDuplicates: true,
      positionClass: 'toast-top-right'
    })
  ],
  providers: [RequestFormService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
