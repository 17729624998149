import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    public spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.spinner.show();
    return next.handle(request).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          this.spinner.hide();
        }
      }),
      catchError((err) => {
        this.spinner.hide();
        if (err.status === 401) {
          this.toastr.error(err.error.message);
        } else if (err.status === 422) {
          this.toastr.error(err.error.message);
          // if (err.error.errors?.file_name.length>0) {
          //   this.toastr.error(err.error.errors?.file_name[0]);
          // }else if (err.error.errors?.last_name.length>0) {
          //   this.toastr.error(err.error.errors?.last_name[0]);
          // }else {
          //   this.toastr.error(err.error.message);
          // }
        } else if (err.status === 403) {
          this.toastr.error(err.error.message);
        } else if (err.status === 404) {
          this.toastr.error('URL is not valid. Please open correct url.');
        } else if (err.status === 405) {
          this.toastr.error(err.error.message);
        } else if (err.status === 410) {
          this.router.navigate(['not-found']);
        } else if (err.status === 466) {
          this.toastr.error('Something went wrong');
        } else if (err.status === 400) {
          this.toastr.error(err.error);
          // tslint:disable-next-line: triple-equals
        } else if (err.status == 0) {
          this.toastr.error(err.statusText);
        } else if (err.status == 500) {
          this.toastr.error(err.error.message);
        }
        return throwError(err);
      })
    );
  }
}
