

    <div class="modal-header" #mymodal>
      <h1 class="modal-title" id="modal-basic-title">Work Order Submitted!</h1>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
     <p >Your work order has been <br/>
        successfully submitted!</p>
        <p >You will receive an email confirmation <br/>
        of your order once processed. </p>
        <p >Thank you for your business! </p>
    </div>
    <div class="modal-footer" >
      <div class="form-btn">
        <img src="../../../assets/images/arrow.png" class="arrow-btn">
      <button type="button" class="btn btn-primary" (click)="close()">Request Another Work Order</button>
    </div></div>
 