import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class RequestFormService {
  BaseUrl: any;

  constructor(public http: HttpClient) {
    this.BaseUrl = environment.APIEndpoint;
  }

  add(data: any): Observable<any> {
    const url = this.BaseUrl + '/send-email';
    // let headers = SharedService.getPutHeader();
    return this.http.post(url, data).pipe(
      map(response => {
        return response;
      }
      ));
  }

  uploadFile(data: FormData): Observable<any> {
    let url = this.BaseUrl + '/file-upload';
    // return this.sharedService.postFileApi(url, data);
    return this.http.post(url, data).pipe(
      map(response => {
        return response;
      }
      ));
  }

}
