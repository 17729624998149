import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-request-form-success',
  templateUrl: './request-form-success.component.html',
  styleUrls: ['./request-form-success.component.css']
})
export class RequestFormSuccessComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  close()
  {
    this.modalService.dismissAll();

  }


}
