
  <div class="bg-start">
    <div class="top"></div>
    <div class="container-sm ">
  <div class="row">
   
    <div class="col-md-12">
      <div class="form-card">
        <header><a class="logo">
          <img src="../../../assets/images/img-logo.png" class="img-fluid"></a></header>
      <form  [formGroup]="Form">

        <h2 class="text-center page-head">Schedule Service Today</h2>
        <h5 class="card-title">CUSTOMER INFO</h5>
       
        <div class="row">

          <div class="col-sm-12">
            <label>Customer Name*</label>
            <input type="text" formControlName="customer_name" class="form-control mb-2">
            <div *ngIf="f.customer_name.invalid && (f.customer_name.dirty || f.customer_name.touched)">
              <div *ngIf="f.customer_name.errors.required" class="text-danger formValidation">
                Customer name is required.
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label>First Name*</label>
            <input type="text" formControlName="first_name" class="form-control mb-2">
            <div *ngIf="f.first_name.invalid && (f.first_name.dirty || f.first_name.touched)">
              <div *ngIf="f.first_name.errors.required" class="text-danger formValidation">
                First name is required.
              </div>
            </div>
          </div>
          <div class="col-6 pl-1">
            <label>Last Name</label>
            <input type="text" formControlName="last_name" class="form-control mb-2">
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <label>Phone Number*</label>
            <input type="text" formControlName="phone_number"  mask="000-000-0000" class="form-control mb-2">
            <div *ngIf="f.phone_number.invalid && (f.phone_number.dirty || f.phone_number.touched)">
              <div *ngIf="f.phone_number.errors.required" class="text-danger formValidation">
                Phone number is required.
              </div>
              <div *ngIf="f.phone_number.errors.pattern" class="text-danger formValidation">
                Please enter 10 digits phone number.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label>Email Address*</label>
            <div class="row" *ngFor="let email of getEmail(); let i = index;">
              <div class="col-sm-12">
                <div class="form-group mb-1">
                  <div class="close_box">
                    <input type="text" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                      maxlength="200" [formControl]="email" class="form-control" placeholder="Enter email">
                    <div *ngIf="i>0">
                      <a (click)="deleteRow(i,'email')" class="close_icon"><i class="fa fa-minus" aria-hidden="true"></i> </a>
                    </div>
                  </div>
                  <div *ngIf="email.invalid && (email.dirty || email.touched)">
                    <div *ngIf="email.errors?.required" class="text-danger formValidation">Email required</div>
                    <div *ngIf="email.errors?.pattern" class="text-danger formValidation">Please enter valid email
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 ">
            <div class="form-group add-more pull-right">
              <a (click)="addEmail()">+ Add more email addresses</a>
            </div>
          </div>
        </div>

        <h5 class="card-title">SERVICE INFO</h5>
        <div class="about-border"></div>
        <div class="row">
          <div class="col-sm-12">
            <label>Service Location(ex: 1235 Mary Lane Vancouver BC)*</label>
            <input type="text" formControlName="location" class="form-control mb-2">
            <div *ngIf="f.location.invalid && (f.location.dirty || f.location.touched)">
              <div *ngIf="f.location.errors.required" class="text-danger formValidation">
                Service location is required.
              </div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-12">
            <label>Type of Container*</label>
            <select class="browser-default form-control custom-select mb-2" formControlName="container_type">
              <option value="">Select type of container</option>
              <option value="30 yard construction waste">30 yard construction waste</option>
              <option value="30 yard construction recycling">30 yard construction recycling</option>
              <option value="Locations recycling/waste container">Locations recycling/waste container</option>
              <option value="Other">Other - Please define in comment section</option>
            </select>
            <div *ngIf="f.container_type.invalid && (f.container_type.dirty || f.container_type.touched)">
              <div *ngIf="f.container_type.errors.required" class="text-danger formValidation">
                Container type is required.
              </div>
            </div>
          </div>
          <div class="col-12 ">
            <label>PO Number*</label>
            <label class="supply-icon"><input type="checkbox" (change)="selectSupply($event)"
                style="margin-right:4px;">Supply Later</label>
            <input type="text" formControlName="po_number" class="form-control mb-2">
            <div *ngIf="f.po_number.invalid && (f.po_number.dirty || f.po_number.touched)">
              <div *ngIf="f.po_number.errors.required" class="text-danger formValidation">
                PO Number is required.
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <label>Date for bin drop off*</label>
            <div>
              <input class="form-control mb-2"  type="date"   formControlName="drop_off_date" >
            </div>
            <div *ngIf="f.drop_off_date.invalid && (f.drop_off_date.dirty || f.drop_off_date.touched)">
              <div *ngIf="f.drop_off_date.errors.required" class="text-danger formValidation">
                Date for bin drop off is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-left: 5px;">
         

          <label class="custom_radio"> <input id="Swap" type="checkbox" value="Swap" name="service_type" (change)="serviceChange($event)"
            formControlName="service_type"> <span></span> Add Swap/Remove and Replace (If required)</label>
        </div>
        <div class="row" *ngIf="checkSwap">
          <div class="col-sm-12">
            <label>Date for bin swap*</label>
            <div class="row" *ngFor="let swapDate of getSwapDate(); let i = index;">
              <div class="col-sm-12">
                <div class="form-group mb-3">
                  <div class="close_box">
                      <div>
                        <input class="form-control mb-2"  type="date"    [formControl]="swapDate" >
                      </div>
                    <div *ngIf="i>0">
                      <a (click)="deleteRow(i,'swap')" class="close_icon"><i class="fa fa-minus" aria-hidden="true"></i> </a>
                    </div>
                  </div>
                  <div *ngIf="swapDate.invalid && (swapDate.dirty || swapDate.touched)">
                    <div *ngIf="swapDate.errors?.required" class="text-danger formValidation">Swap date is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="checkSwap">
          <div class="col-sm-12 ">
            <div class="form-group add-more pull-right">
              <a (click)="addSwapDate()">+ Add more swap date</a>
            </div>
          </div>
        </div>
        <div class="row">
          <div  class="col-sm-12">
            <label>Date for bin pick-up (If required)</label>
            <input class="form-control mb-2"  type="date"   formControlName="pick_up_date" >
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <label>Special Instructions to Driver/Dispatch about bin location or other</label>
            <textarea type="text" formControlName="special_instructions" class="form-control mb-2" rows="3"></textarea>
          </div>

        </div>
        <div fxFlex="92">
          <label>Choose Files:Map/Picture/Other</label>
          <div class="fileUploader">
            <button mat-raised-button (click)="onFileUpload()">Choose Files</button>
            <input type="file" accept="image/*"  id="docUpload" name="docUpload" style="display:none;" />
            <div fxLayout="row" fxLayoutAlign="center center">
              <div *ngIf="fileError !== ''">{{fileError}}</div>
              <div *ngIf="myFile!==''">
                <p>{{myFile}}  <a (click)="deleteFile()" style="margin-left: 10px;"><i class="fa fa-trash" aria-hidden="true"></i> </a></p>
                <p style="color: green;">{{fileSuccess}}</p>
              </div>
             
            </div>
          </div>
        </div>

        <!-- Send button -->
        <div class="form-btn">
          <img src="../../../assets/images/arrow.png" class="arrow-btn">
          <button type="submit" 
          class="btn btn-primary" (click)="onSubmit()">Request Work Order</button>
        </div>
        <div class="info">*Work Order will be emailed to you once the service request has been scheduled</div>
      </form>
    </div>
    </div>
  
  </div>
</div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>